import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule, USER_PROVIDED_META_REDUCERS } from '@ngrx/store';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { globalFailureReducerFactory } from 'ngrx-loading-state';
import { ToastrModule } from 'ngx-toastr';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaModule, RecaptchaFormsModule } from "ng-recaptcha";


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GlobalErrorHandler } from './error-handling/global-error-handler.service';
import { AuthentificationModule } from './modules/authentification/authentification.module';
import { LayoutMainModule } from './modules/layout-main/layout-main.module';
import { SharedModule } from './modules/shared/shared.module';
import { CatalogStoreModule } from './store/catalog/catalog-store.module';
import { environment } from 'src/environments/environment';
import { globalFailureHandler } from './error-handling/global-failure-handler.service';
import { GlobalLoaderStoreModule } from './store/global-loader/global-loader-store.module';
import { ImageViewerStoreModule } from './store/image-viewer/image-viewer.store.module';


registerLocaleData(localeFr, 'fr');

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    }),
    StoreModule.forRoot({}),
    EffectsModule.forRoot({}),
    ToastrModule.forRoot({
      preventDuplicates: true,
    }),
    RecaptchaModule,
    RecaptchaFormsModule,
    SharedModule,
    LayoutMainModule,
    CatalogStoreModule,
    GlobalLoaderStoreModule,
    ImageViewerStoreModule,
    AppRoutingModule
  ],
  providers: [
     {
       provide: USER_PROVIDED_META_REDUCERS,
       useValue: [globalFailureReducerFactory(globalFailureHandler)]
      },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },

    { provide: LOCALE_ID, useValue: 'fr' },
    { provide: MAT_DATE_LOCALE, useValue: 'fr' },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.RECAPTCHA_SITE_KEY },
    provideClientHydration()
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }


export function httpTranslateLoader(http: HttpClient) {
  const random = Math.random();
  return new TranslateHttpLoader(http);
}

