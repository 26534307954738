import { FailureAction, LoadingState } from "ngrx-loading-state";

export function globalFailureHandler(failureAction: FailureAction, _state: LoadingState) {
    // Using setTimeout to ensure this doesn't stop code flow and impact downstream processing.
    setTimeout(() => {
        if ( failureAction.error.status == 500) { // failureAction.error.status == 0 ||
            throw Error(JSON.stringify([{ code: '500', message: 'Oops, une erreur s\'est produite !' }]));
        }
    

        if (failureAction.error.status == 401) {
            throw Error(JSON.stringify([{ code: '401', message: 'Non autorisé' }]));
        }

        if (failureAction.error.status == 404) {
            throw Error(JSON.stringify([{ code: '404', message: 'Non trouvé' }]));
        }


        const globalFailureAction: any = failureAction;

        if (globalFailureAction.error != 'error') {
            throw Error(JSON.stringify(globalFailureAction.error.error.errors));
        }
        else {
            throw Error(JSON.stringify(globalFailureAction.errors));
        }



    });
}
