import { isPlatformBrowser } from '@angular/common';
import { ErrorHandler, Inject, Injectable, Injector, NgZone, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';

import { Error } from '../models/results/base-result.model';
import { CustomToastrService } from '../services/custom-toastr.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {


  constructor(private zone: NgZone, @Inject(Injector) private readonly injector: Injector,
  private router:Router,
  @Inject(PLATFORM_ID) private platformId: any,) { }

  private get toastr() {
    return this.injector.get(CustomToastrService);
}


  handleError(error: any): void {
    // Exception can happen anywhere, including from outside of NgZone. So to display
    // the snackbar component properly, we need to ensure it's called from within zones.
   
    // console.log(error)
    if (error && error.message && error.message.includes('[{') && isPlatformBrowser(this.platformId)) {
        const errors:Error[] = JSON.parse(error.message);
        if(errors[0].code == '404'){
          this.router.navigate(['/', 'fr-fr' ,'error', '404']);
        }
        if(errors[0].code == '500'){
          this.router.navigate(['/', 'fr-fr' ,'error', '500']);
        }

        this.zone.run(() => {
        errors.forEach(error => {
          if(error.code != '404' && error.code != '500')
            this.toastr.error(error.message);
        });
    });
    }
    else {
      if(error.status === 404){
        this.router.navigate(['/', 'fr-fr' ,'error', '404']);
      }else if(error.status == 500){
        this.router.navigate(['/', 'fr-fr' ,'error', '500']);
      }
    }

  }
}
