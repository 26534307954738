<footer class="bg-black">
  <div class="pt-5 container ">
    <section class="row mx-0 px-0 pb-5 justify-content-center">
      <div class="col-md-11 col-12 mx-auto row justify-content-center">
        <div class="col-5 col-lg-2 row mx-0 px-lg-0 p-3 py-lg-0 justify-content-center block-avantage">
          <div class="col-12 col-lg-5 text-center text-lg-right my-auto">
            <img src="assets/img/schedule.svg" class="img-advantage m-auto" alt="calendrier"
              title="Vous pouvez changer d'avis" loading="lazy" />
          </div>
          <p
            class="text-center pt-2 pt-lg-0 text-lg-left col-12 col-lg-7 px-0 p-advantage my-auto text-white f-size-14px">
            {{'14daysToChangeYourMind' | translate}}
          </p>
        </div>
        <div class="col-1 d-flex justify-content-center my-auto p-separator-mobile">
          <div class="separator-desktop separator-mobile-vertical"></div>
        </div>
        <div class="col-5 col-lg-2 row mx-0 px-lg-0 p-3 py-lg-0 justify-content-center">
          <div class="col-12 col-lg-5 text-center text-lg-right my-auto">
            <img src="assets/img/paiement_secure.svg" alt="security" title="paiement security"
              class="img-advantage m-auto" loading="lazy" />
          </div>
          <p
            class="text-center pt-2 pt-lg-0 text-lg-left col-12 col-lg-7 px-0 p-advantage my-auto text-white f-size-14px">
            {{'securePaiement' | translate}}
          </p>
        </div>
        <div class="col-lg-1 d-none d-lg-flex justify-content-center">
          <div class="separator-desktop"></div>
        </div>
        <div class="col-6 d-lg-none justify-content-center">
          <div class="separator-mobile-horizontal"></div>
        </div>
        <div class="col-6 d-lg-none justify-content-center">
          <div class="separator-mobile-horizontal"></div>
        </div>
        <div class="col-5 col-lg-2 row mx-0 px-lg-0 p-3 py-lg-0 justify-content-center">
          <div class="col-12 col-lg-5 text-center text-lg-right my-auto">
            <img src="assets/img/delivery.svg" alt="delivery" title="free delivery" class="img-advantage m-auto"
              loading="lazy" />
          </div>
          <p
            class="text-center pt-2 pt-lg-0 text-lg-left col-12 col-lg-7 px-0 p-advantage my-auto text-white f-size-14px">
            {{'freeDeliveryFooter' | translate}}
          </p>
        </div>
        <div class="col-1 d-flex justify-content-center my-auto p-separator-mobile">
          <div class="separator-desktop separator-mobile-vertical"></div>
        </div>
        <div class="col-5 col-lg-2 row mx-0 px-lg-0 p-3 py-lg-0 justify-content-center">
          <div class="col-12 col-lg-5 text-center text-lg-right my-auto">
            <img src="assets/img/paiement_multi.svg" alt="payment" title="delay in payment" class="img-advantage m-auto"
              loading="lazy" />
          </div>
          <p
            class="text-center pt-2 pt-lg-0 text-lg-left col-12 col-lg-7 px-0 p-advantage my-auto text-white f-size-14px">
            {{'multiplePaiement' | translate}}
          </p>
        </div>
      </div>
    </section>
    <section class="row mx-0 px-0 pt-5 pb-md-5 border-top-footer">
      <div class="col-12 px-0 mx-auto row justify-content-center">
        <!-- NEWSLETTER -->
        <div class="col-12 col-lg-4 mb-4">
          <div class="row mx-0 px-0 newsletter-box">
            <div class="col-12 px-0">
              <h5 class="text-white f-size-18px">{{'newsletter' | translate}}</h5>
            </div>
            <p class="col-12 px-0 f-size-md-16px f-size-14px text-white">{{'newsletterText' | translate}}</p>
            <p class="col-12 px-0 f-size-12px text-white">{{'newsletterAccept' | translate}}<br /><a
                [routerLink]="['/',  translateService.currentLang ? translateService.currentLang : translateService.defaultLang, 'info',  'cgv']"
                class="text-main f-size-12px">{{'ourPrivacyPolicy' | translate}}</a></p>
            <div class="col-12 px-0">
              <div class=" rounded newsletter-input d-flex p-0">
                <input [formControl]="emailCtrl"
                  class="w-100 f-size-md-16px f-size-14px bg-transparent text-grey-dark border-0 px-3 rounded"
                  type="text" [placeholder]="'yourEmailAdress' | translate" />
                <button class="bg-main-gradient rounded d-flex my-auto ml-auto" (click)="onSubmit()"
                  [disabled]="!emailCtrl.valid">
                  <img src="assets/img/send.svg" alt="send" title="send your email" class="m-auto mh-18px"
                    loading="lazy" />
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- SMALLMX -->
        <div class="col-12 col-lg-2 row mx-0 px-0 mb-4">
          <div class="col-12 d-none d-lg-block">
            <h5 class="mb-4 f-size-18px text-white">SmallMX</h5>
            <p><a [routerLink]="['/',  translateService.currentLang ? translateService.currentLang : translateService.defaultLang, 'info',  'notre-boutique']"
                class="py-2 f-size-14px text-white">{{'ourShop' | translate}}</a></p>
            <p><a [routerLink]="['/',  translateService.currentLang ? translateService.currentLang : translateService.defaultLang, 'info',  'qui-sommes-nous']"
                class="py-2 f-size-14px text-white">{{'whoAreWe' | translate}}</a></p>
            <p><a [routerLink]="['/',  translateService.currentLang ? translateService.currentLang : translateService.defaultLang, 'info',  'cgv']"
                class="py-2 f-size-14px text-white">{{'tos' | translate}}</a></p>
            <p><a [routerLink]="['/',  translateService.currentLang ? translateService.currentLang : translateService.defaultLang, 'info',  'cookies']"
                class="py-2 f-size-14px text-white text-capitalize">{{'cookies' | translate}}</a></p>
                <p><a onclick="javascript:__tcfapi('show', 2, function(){}, {jumpAt: 'privacy'})"
                  class="py-2 f-size-14px text-white text-capitalize cursor-pointer">Centre de
                  confidentialité</a></p>
               
            <p><a [routerLink]="['/',  translateService.currentLang ? translateService.currentLang : translateService.defaultLang, 'info',  'mentions-legales']"
                class="py-2 f-size-14px text-white">{{'legalNotice' | translate}}</a></p>
          </div>
          <div class="col-12 d-lg-none">
            <div class="w-100">
              <button class="border-0 w-100 d-flex justify-content-between bg-black p-0" type="button"
                data-toggle="collapse" data-target="#collapseSMX" aria-expanded="false" aria-controls="collapseSMX">
                <h5 class="text-white f-size-18px">SmallMX</h5><span
                  class="k-chevron-down f-size-24px text-main-gradient"></span>
              </button>
            </div>
            <div class="collapse mt-3" id="collapseSMX">
              <div class="">
                <p><a [routerLink]="['/',  translateService.currentLang ? translateService.currentLang : translateService.defaultLang, 'info',  'notre-boutique']"
                    class="py-2 f-size-14px text-white">{{'ourShop' | translate}}</a></p>
                <p><a [routerLink]="['/',  translateService.currentLang ? translateService.currentLang : translateService.defaultLang, 'info',  'qui-sommes-nous']"
                    class="py-2 f-size-14px text-white">{{'whoAreWe' | translate}}</a></p>
                <p><a [routerLink]="['/',  translateService.currentLang ? translateService.currentLang : translateService.defaultLang, 'info',  'cgv']"
                    class="py-2 f-size-14px text-white">{{'tos' | translate}}</a></p>
                <p><a [routerLink]="['/',  translateService.currentLang ? translateService.currentLang : translateService.defaultLang, 'info',  'cookies']"
                    class="py-2 f-size-14px text-white text-capitalize">{{'cookies' | translate}}</a></p>
                    <p><a onclick="javascript:__tcfapi('show', 2, function(){}, {jumpAt: 'privacy'})"
                      class="py-2 f-size-14px text-white text-capitalize cursor-pointer">Centre de
                      confidentialité</a></p>
                <p><a [routerLink]="['/',  translateService.currentLang ? translateService.currentLang : translateService.defaultLang, 'info',  'mentions-legales']"
                    class="py-2 f-size-14px text-white">{{'legalNotice' | translate}}</a></p>
              </div>
            </div>
          </div>
        </div>
        <!-- SERVICE CLIENT -->
        <div class="col-12 col-lg-2 row mx-0 px-0 mb-4">
          <div class="col-12 d-none d-lg-block">
            <h5 class="mb-4 text-white f-size-18px">{{'customerService' | translate}}</h5>
            <p> <a class="py-2 f-size-14px text-white" href="https://smallmx.zendesk.com/hc/fr" target="_blank">{{'FAQ'
                | translate}}</a></p>
            <p><a [routerLink]="['/',  translateService.currentLang ? translateService.currentLang : translateService.defaultLang, 'info',  'garantie-des-prix-les-plus-bas']"
                class="py-2 f-size-14px text-white">{{'lowestPriceGuarantee' | translate}}</a></p>
            <p><a [routerLink]="['/',  translateService.currentLang ? translateService.currentLang : translateService.defaultLang, 'info',  'promotion']"
                class="py-2 f-size-14px text-white">{{'promotion' | translate}}</a></p>
            <p><a [routerLink]="['/',  translateService.currentLang ? translateService.currentLang : translateService.defaultLang, 'info',  'paiement']"
                class="py-2 f-size-14px text-white">{{'payment' | translate}}</a></p>
            <p><a [routerLink]="['/',  translateService.currentLang ? translateService.currentLang : translateService.defaultLang, 'info',  'livraison']"
                class="py-2 f-size-14px text-white">{{'shipping' | translate}}</a></p>
            <p><a [routerLink]="['/',  translateService.currentLang ? translateService.currentLang : translateService.defaultLang, 'info',  'sav']"
                class="py-2 f-size-14px text-white">{{'returnSAV' | translate}}</a></p>
            <p><a (click)="scroll()" [routerLink]="['/', translateService.currentLang ? translateService.currentLang : translateService.defaultLang, 'contact']"
                class="py-2 f-size-14px text-white">{{'contact' | translate}}</a></p>
          </div>
          <div class="col-12 d-lg-none">
            <div class="w-100">
              <button class="border-0 w-100 d-flex justify-content-between bg-black p-0" type="button"
                data-toggle="collapse" data-target="#collapseClient" aria-expanded="false"
                aria-controls="collapseClient">
                <h5 class="text-white f-size-18px">{{'customerService' | translate}}</h5><span
                  class="k-chevron-down f-size-24px text-main-gradient"></span>
              </button>
            </div>
            <div class="collapse mt-3" id="collapseClient">
              <div class="">
                <p> <a class="py-2 f-size-14px text-white" href="https://smallmx.zendesk.com/hc/fr"
                    target="_blank">{{'FAQ' | translate}}</a></p>
                <p><a [routerLink]="['/',  translateService.currentLang ? translateService.currentLang : translateService.defaultLang, 'info',  'garantie-des-prix-les-plus-bas']"
                    class="py-2 f-size-14px text-white">{{'lowestPriceGuarantee' | translate}}</a></p>
                <p><a [routerLink]="['/',  translateService.currentLang ? translateService.currentLang : translateService.defaultLang, 'info',  'promotion']"
                    class="py-2 f-size-14px text-white">{{'promotion' | translate}}</a></p>
                <p><a [routerLink]="['/',  translateService.currentLang ? translateService.currentLang : translateService.defaultLang, 'info',  'paiement']"
                    class="py-2 f-size-14px text-white">{{'payment' | translate}}</a></p>
                <p><a [routerLink]="['/',  translateService.currentLang ? translateService.currentLang : translateService.defaultLang, 'info',  'livraison']"
                    class="py-2 f-size-14px text-white">{{'shipping' | translate}}</a></p>
                <p><a [routerLink]="['/',  translateService.currentLang ? translateService.currentLang : translateService.defaultLang, 'info',  'sav']"
                    class="py-2 f-size-14px text-white">{{'returnSAV' | translate}}</a></p>
                <p><a (click)="scroll()" [routerLink]="['/', translateService.currentLang ? translateService.currentLang : translateService.defaultLang, 'contact']"
                    class="py-2 f-size-14px text-white">{{'contact' | translate}}</a></p>
              </div>
            </div>
          </div>
        </div>
        <!-- PAIEMENT SECURISE -->
        <div class="col-12 col-lg-3 row mx-0 px-0 mb-4">
          <div class="col-12 row mx-0 px-0 d-none d-lg-block">
            <h5 class="mb-4 text-white col-12">{{'securePaiement' | translate}}</h5>
            <div class="row px-2 col-lg-12 col-9 mx-0">
              <!-- <div *ngFor="let gateway of gateways" class="col-3 px-1 mt-2">
        <img [src]="gateway.pictureFile" />
      </div> -->

              <!-- <div *ngFor="let gateway of gateways"
              class="col-3 px-1 mt-2"><img [src]="gateway.fullUrlPictureSmall" [alt]="gateway.designation"
                  title="carte-bleue" loading="lazy" /></div> -->
              <div *ngIf="hasGatewayCB" class="col-3 px-1 mt-2"><img src="assets/img/carte-bleue.svg"
                  alt="carte-bleue logo" title="carte-bleue" loading="lazy" /></div>
              <div *ngIf="hasGatewayWire" class="col-3 px-1 mt-2"><img src="assets/img/virement.svg" alt="virement logo"
                  title="virement" loading="lazy" />
              </div>
              <div *ngIf="hasGatewayCB" class="col-3 px-1 mt-2"><img src="assets/img/visa.svg" alt="visa logo"
                  title="visa" loading="lazy" />
              </div>
              <div *ngIf="hasGatewayCB" class="col-3 px-1 mt-2"><img src="assets/img/mastercard.svg"
                  alt="mastercard logo" title="mastercard" loading="lazy" /></div>
              <div *ngIf="hasGatewayPaypal" class="col-3 px-1 mt-2"><img src="assets/img/paypal.svg" alt="paypal logo"
                  title="paypal" loading="lazy" /></div>
              <!-- <div class="col-3 px-1 mt-2"><img src="assets/img/applePay.svg" alt="applePay logo" title="applePay"
                  loading="lazy" />
              </div>
              <div class="col-3 px-1 mt-2"><img src="assets/img/googlePay.svg" alt="googlePay logo" title="googlePay"
                  loading="lazy" /></div> -->
            </div>
            <div class="rounded-pill multi-payment d-flex justify-content-between mt-5 px-3 col-lg-12 col-9">
              <div class="col-3 px-1 mt-2 my-auto"><img src="assets/img/payment2x.svg" alt="payment2x logo"
                  title="payment2x" loading="lazy" /></div>
              <div class="col-3 px-1 mt-2 my-auto"><img src="assets/img/payment3x.svg" alt="payment3x logo"
                  title="payment3x" loading="lazy" /></div>
              <div class="col-3 px-1 mt-2 my-auto"><img src="assets/img/payment4x.svg" alt="payment4x logo"
                  title="payment4x" loading="lazy" /></div>
              <div class="col-3 px-1 mt-2 my-auto"><img src="assets/img/payment48x.svg" alt="payment48x logo"
                  title="payment48x" loading="lazy" /></div>
            </div>
          </div>
          <div class="col-12 d-lg-none">
            <div class="w-100">
              <button class="border-0 w-100 d-flex justify-content-between bg-black p-0" type="button"
                data-toggle="collapse" data-target="#collapsePaiement" aria-expanded="false"
                aria-controls="collapseClient">
                <h5 class="text-white f-size-18px">{{'securePaiement' | translate}}</h5><span
                  class="k-chevron-down f-size-24px text-main-gradient"></span>
              </button>
            </div>
            <div class="collapse mt-3" id="collapsePaiement">
              <div class="row px-0 col-lg-12 col-9 mx-0">
                <!-- <div *ngFor="let gateway of gateways" class="col-3 px-1 mt-2">
      <img [src]="gateway.pictureFile" />
    </div> -->

                <div *ngIf="hasGatewayCB" class="col-3 px-1 mt-2"><img src="assets/img/carte-bleue.svg"
                    alt="carte-bleue logo" title="carte-bleue" loading="lazy" /></div>
                <div *ngIf="hasGatewayWire" class="col-3 px-1 mt-2"><img src="assets/img/virement.svg"
                    alt="virement logo" title="virement" loading="lazy" /></div>
                <div *ngIf="hasGatewayCB" class="col-3 px-1 mt-2"><img src="assets/img/visa.svg" alt="visa logo"
                    title="visa" loading="lazy" /></div>
                <div *ngIf="hasGatewayCB" class="col-3 px-1 mt-2"><img src="assets/img/mastercard.svg"
                    alt="mastercard logo" title="mastercard" loading="lazy" /></div>
                <div *ngIf="hasGatewayPaypal" class="col-3 px-1 mt-2"><img src="assets/img/paypal.svg" alt="paypal logo"
                    title="paypal" loading="lazy" /></div>
                <!-- <div class="col-3 px-1 mt-2"><img src="assets/img/applePay.svg" alt="applePay logo" title="applePay"
                    loading="lazy" /></div>
                <div class="col-3 px-1 mt-2"><img src="assets/img/googlePay.svg" alt="googlePay logo" title="googlePay"
                    loading="lazy" /></div> -->
              </div>
              <div class="rounded-pill multi-payment d-flex justify-content-between mt-5 px-3 col-lg-12 col-9">
                <div class="col-3 px-1 mt-2 my-auto"><img src="assets/img/payment2x.svg" alt="payment2x logo"
                    title="payment2x" loading="lazy" /></div>
                <div class="col-3 px-1 mt-2 my-auto"><img src="assets/img/payment3x.svg" alt="cpayment3x logo"
                    title="payment3x" loading="lazy" /></div>
                <div class="col-3 px-1 mt-2 my-auto"><img src="assets/img/payment4x.svg" alt="payment4x logo"
                    title="payment4x" loading="lazy" /></div>
                <div class="col-3 px-1 mt-2 my-auto"><img src="assets/img/payment48x.svg" alt="payment48x logo"
                    title="payment48x" loading="lazy" /></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- SOCIAL MEDIA -->
    <section class="row mx-0 px-0 py-lg-0 py-2 border-top-footer text-lg-left text-center justify-content-center">
      <div class="col-11 mx-auto row justify-content-between">
        <div class="col-lg-6 col-12 row order-lg-1 order-2 my-3 mx-auto">
          <div class="col-lg-4 col-12 px-0 text-white">
            <span class="f-size-18px font-weight-bold social-media text-white">{{'followUs' | translate}}</span><br />
            <span class="f-size-18px font-weight-bold social-media text-white">{{'onSocialMedia' | translate}}</span>
          </div>
          <div class="col-lg-4 col-12 mt-md-auto mt-4">
            <a href="https://www.facebook.com/smallmx.fr" target="_blank"><img class="mr-2 mw-38px" loading="lazy"
                alt="logo facebook" title="facebook" src="assets/img/facebook.svg" /></a>
            <a href="https://www.instagram.com/smallmx_fr" target="_blank"><img class="mr-2 mw-38px" loading="lazy"
                alt="logo instagram" title="instagram" src="assets/img/instagram.svg" /></a>
          </div>
        </div>
        <div class="col-lg-6 col-12 order-3 mb-3 mb-md-1 mt-3 d-flex justify-content-center justify-content-lg-end">
          <p class="mt-auto"><img class="logo-footer" loading="lazy" src="assets/img/main/logo.png" alt="logo smallmx"
              title="smalllmx" /><br class="d-lg-none" /><br class="d-lg-none" /><span
              class="f-size-12px text-white">&copy; SmallMX {{currentYear}} {{ 'allRightsReserved' | translate}}</span>
          </p>
        </div>
      </div>
    </section>
  </div>
</footer>
