import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID, Renderer2 } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router, RoutesRecognized } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter, map,pairwise } from 'rxjs';
import { ScrollToService } from './services/scroll-to.service';
import { SideNavService } from './services/sidenav.service';
import { AuthFacade } from './store/authentification/auth.facade';
import { CartFacade } from './store/cart/cart.facade';
import { GlobalLoaderFacade } from './store/global-loader/global-loader.facade';
import { SharedFacade } from './store/shared/shared.facade';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'An-Platform-Front-SmallMX';

  
  constructor(public translate:TranslateService,
    @Inject(PLATFORM_ID) private platformId: any,
    private route:ActivatedRoute,
    private router:Router,
    private sharedFacade:SharedFacade,
    private cartFacade:CartFacade,
    private authFacade:AuthFacade,
    private renderer: Renderer2,
    public globalLoaderFacade:GlobalLoaderFacade,
    private scrollToService:ScrollToService,
    private sideNavService:SideNavService
   ){
    translate.addLangs(['en-us', 'fr-fr']);
    translate.setDefaultLang('fr-fr');
    translate.use('fr-fr');
  }

  ngOnInit(): void {
    // this.cartFacade.fetchCart({ setCart: true, calcShipping: false, calcDiscount: true, includeCarriers: false, includeGateways: false, validate:false });

    if (isPlatformBrowser(this.platformId)) {
      this.authFacade.autoLogin({});
    
      
      let loader = this.renderer.selectRootElement('#app-loading');
      if (loader && loader.style.display != "none") loader.style.display = "none"; //hide loader
    }

    this.sharedFacade.fetchSettings({});
    this.sharedFacade.fetchCountries({});
    // this.sharedFacade.fetchWebsiteLanguages({});
    

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((e: any) => {
      const lang = e.url.split('/')[1];

      if(this.translate.langs.includes(lang)){
        this.switchLang(lang);
        this.sharedFacade.setCurrentLanguage({ language: lang });
      }
      else{
        this.sharedFacade.setCurrentLanguage({ language: 'fr-fr' });
        this.router.navigate(['/', 'fr-fr']);
      }

    });

    this.router.events.pipe(
      filter((event) => event instanceof NavigationStart),
      map(() => this.rootRoute(this.route)),
    ).subscribe((route: ActivatedRoute) => {
      // if(route.component?.name != 'ProductDetailsComponent'){
      //   this.scrollToService.scrollToTop(); 
      // }

      if((route.snapshot.routeConfig?.data && !route.snapshot.routeConfig.data['noScroll']) || !route.snapshot.routeConfig?.data){
        this.scrollToService.scrollToTop(); 
      }
      this.sideNavService.closeSideNavLeft();
      this.sideNavService.closeSideNavRight();
    });

    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.rootRoute(this.route)),
    ).subscribe((route: ActivatedRoute) => {
      // if(route.component?.name != 'ProductDetailsComponent'){
      //   this.scrollToService.scrollToTop(); 
      // }
      if((route.snapshot.routeConfig?.data && !route.snapshot.routeConfig.data['noScroll']) || !route.snapshot.routeConfig?.data){
        this.scrollToService.scrollToTop(); 
      }
    });

    
  }

  switchLang(lang: string) {
    this.translate.use(lang);
  }

  private rootRoute(route: ActivatedRoute): ActivatedRoute {
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route;
  }

}
