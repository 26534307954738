import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Category } from 'src/app/models/category.model';
import { ScrollToService } from 'src/app/services/scroll-to.service';
import { CatalogFacade } from 'src/app/store/catalog/catalog.facade';
import { environment } from 'src/environments/environment';
import { DialogSliderSelectorComponent } from '../dialog-slider-selector/dialog-slider-selector.component';
import { DialogChooseVehiculeComponent } from './dialog-choose-vehicule/dialog-choose-vehicule.component';

@Component({
  selector: 'app-choose-vehicule',
  templateUrl: './choose-vehicule.component.html',
  styleUrls: ['./choose-vehicule.component.scss']
})
export class ChooseVehiculeComponent implements OnInit, OnDestroy {

  @Input() dialogRef!: MatDialogRef<DialogChooseVehiculeComponent>;

  @Input() selectedCategories: number[] = [];
  @Input() selectedVhsTypes: number[] = [];
  @Input() selectedHeight!: number;
  @Input() selectedWeight!: number;
  @Input() showCategories = true;
  @Input() showDropdown = true;
  @Input() dialogId!:number;
  idToAdd = '';

  @Input() scrollValueHeight!: number;
  @Input() scrollValueWeight!: number;

  @Input() displayFull = false;

  attributElectrique = environment.ATTRIBUT_VALUE.ELECTRIQUE;
  attributThermique = environment.ATTRIBUT_VALUE.THERMIQUE;

  categories!: Category[];


  state: boolean = false;
  showdropdown: boolean = false;
  rotateChevron() {
    this.state = !this.state;
  }

  formSearch = new FormGroup({
    categories: new FormControl<number[] | null>(null, [Validators.required]),
    vhsTypes: new FormControl<number[] | null>(null),
    height: new FormControl<number | null>(null, [Validators.required]),
    weight: new FormControl<number | null>(null, [Validators.required]),
  });
  listHeight: number[] = [];
  listWeight: number[] = [];
  attributsValues: { [key: number]: number } = {};

  mobileQuery!: MediaQueryList;

  private _mobileQueryListener: () => void;

  constructor(
    public catalogFacade: CatalogFacade,
    private router: Router,
    private translateService: TranslateService,
    private scrollToService: ScrollToService,
    private dialog: MatDialog,
    private media: MediaMatcher,
    private cdr: ChangeDetectorRef
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 992px)');
    this._mobileQueryListener = () => cdr.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  subscriptions: Subscription[] = [];

  ngOnInit(): void {
    if(this.dialogId){
      this.idToAdd = '_'+this.dialogId.toString();
    }

    for (let i = 60; i < 200; i++) {
      this.listHeight.push(i);
    }
    for (let i = 10; i < 120; i++) {
      this.listWeight.push(i);
    }

    this.subscriptions.push(...[
      this.catalogFacade.getCategories()
        .subscribe(categories => {
          if (categories) {
            this.categories = categories.filter(x => x.categoryParentId == environment.CATEGORIES.VEHICULE)
          }
        }),
    ]);

    if (this.selectedCategories) {
      this.formSearch.patchValue({
        categories: this.selectedCategories
      });
    }
    if (this.selectedVhsTypes) {
      this.formSearch.patchValue({
        vhsTypes: this.selectedVhsTypes
      });
    }
    if (this.selectedHeight) {
      this.formSearch.patchValue({
        height: this.selectedHeight
      });
    }
    if (this.selectedWeight) {
      this.formSearch.patchValue({
        weight: this.selectedWeight
      });
    }
    if (this.showDropdown) {
      this.showdropdown = !this.showdropdown;
    }
  }

  onSubmit() {
    if (this.dialogRef) this.dialogRef.close();
    // this.router.navigate(['/', this.translateService.currentLang ? this.translateService.currentLang : this.translateService.defaultLang, 'catalog', 'selection'],
    // console.log(this.formSearch.value.vhsTypes)
    this.router.navigate(['/', 'fr-fr', 'catalog', 'selection'],
      {
        queryParams: {
          categories: this.formSearch.value?.categories?.join(','),
          vhsTypes: this.formSearch.value.vhsTypes?.length! > 0 ? this.formSearch.value?.vhsTypes?.join(',') : null,
          height: this.formSearch.value.height!,
          weight: this.formSearch.value.weight!,
          scrollHeight: this.scrollValueHeight,
          scrollWeight: this.scrollValueWeight,
        }
      });
    this.scrollToService.scrollToTop();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.subscriptions = [];
  }

  onSelectCategory(category: Category, event: any) {
    const checked = event.target.checked;

    if (this.selectedCategories) {
      const selectedCategory = this.selectedCategories.find(id => id == category.categoryId);

      if (!checked && selectedCategory) {
        const idx = this.selectedCategories.findIndex(id => id == category.categoryId);
        if (idx > -1) {
          this.selectedCategories.splice(idx, 1);
        }
      }
      else {
        if (!selectedCategory) {
          this.selectedCategories.push(category.categoryId);
        }
      }
    }
    this.formSearch.patchValue({
      categories: this.selectedCategories
    });
    event.stopPropagation();

  }
  onSelectVhsType(type: number, event: any) {
    const checked = event.target.checked;

    if(!this.selectedVhsTypes) this.selectedVhsTypes = [];
      const selectedVhsType = this.selectedVhsTypes.find(id => id == type);

      if (!checked && selectedVhsType) {
        const idx = this.selectedVhsTypes.findIndex(id => id == type);
        if (idx > -1) {
          this.selectedVhsTypes.splice(idx, 1);
        }
      }
      else {
        if (!selectedVhsType) {
          this.selectedVhsTypes.push(type);
        }
      }
    
    this.formSearch.patchValue({
      vhsTypes: this.selectedVhsTypes
    });

  }

  onClickSelectCategory(event: any) {
    event.stopPropagation();
  }

  onOpenDialogSliderSelector(type: string) {
    const numbers: number[] = [];
    let data: any = {
      type: type
    };

    if (type == 'height') {
      for (let i = 70; i <= 200; i++) {
        numbers.push(i);
      }
      data = {
        ...data,
        numbers: numbers,
        selectedValue: this.formSearch.value.height,
        scrollValue: this.scrollValueHeight
      };

    }
    else if (type == 'weight') {
      for (let i = 20; i <= 130; i++) {
        numbers.push(i);
      }
      data = {
        ...data,
        numbers: numbers,
        selectedValue: this.formSearch.value.weight,
        scrollValue: this.scrollValueWeight
      };
    }
    else if (type == 'categories') {
      data = {
        ...data,
        categories: this.categories,
        selectedCategories: this.formSearch.value.categories,
        selectedVhsTypes: this.formSearch.value.vhsTypes
      }
    }

    const dialogRef = this.dialog.open(DialogSliderSelectorComponent, {
      width: '100vw',
      minWidth: '100vw',
      height: '100%',
      data: data
    });

    this.subscriptions.push(...[
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          if (type == 'height') {
            this.selectedHeight = result.selectedValue;
            this.formSearch.patchValue({ height: this.selectedHeight });
            this.scrollValueHeight = result.scrollValue;
          } else if (type == 'weight') {
            this.selectedWeight = result.selectedValue;
            this.formSearch.patchValue({ weight: this.selectedWeight });
            this.scrollValueWeight = result.scrollValue;
          }
          else if (type == 'categories') {
            this.selectedCategories = result.selectedCategories;
            // console.log(result)
            this.formSearch.patchValue({
              categories: this.selectedCategories,
              vhsTypes:result.selectedVhsTypes
            });
          }
        }
      })
    ]);
  }
}
