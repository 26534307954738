import { PipeTransform, Pipe } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { Country } from "../../../models/country.model";
import { SharedFacade } from "../../../store/shared/shared.facade";

@Pipe({ name: 'countryFull' })
export class CountryFullPipe implements PipeTransform {
 

  countries: any[] = [];

  constructor(private sharedFacade: SharedFacade,
    private translateService:TranslateService) {
    this.sharedFacade.getCountries()
      .subscribe(countries => {
        this.countries = countries!;
      });

  }
  transform(value: any) {

    if (this.countries) {
      const country = this.countries.find(x => x.twoLetterCode.toLowerCase() == value.toLowerCase());
      if (country) {
        if ((this.translateService.currentLang ? this.translateService.currentLang : this.translateService.defaultLang) === 'fr-fr')
          return country.nameFR;
        else
          return country.name;
      }
    }
    return value;
  }
}