import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

interface DialogData {

}


@Component({
  selector: 'app-dialog-search',
  templateUrl: './dialog-search.component.html',
  styleUrls: ['./dialog-search.component.scss']
})
export class DialogSearchComponent implements OnInit, OnDestroy{

  form!:FormGroup;

  subscriptions:Subscription[] = [];

  constructor(
    public dialogRef: MatDialogRef<DialogSearchComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private router:Router,
    private route:ActivatedRoute,
    public translateService:TranslateService,

  ) { }

  ngOnInit(): void {
    this.form = new FormGroup({
      search:new FormControl(null, [Validators.required])
    });
    
    this.subscriptions.push(...[
      this.route.queryParams.subscribe((params:Params) => {
        if(params['q']){
          this.form.setValue({
            search:params['q']
          });
        }
      })
    ]);
  }

  onSubmit(){
    // console.log('search')
    this.router.navigate(['/',this.translateService.currentLang ? this.translateService.currentLang : this.translateService.defaultLang, 'catalog', 'search'],
    {
      queryParams:{q:this.form.value.search},queryParamsHandling:'merge'
    });
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.subscriptions = [];
  }
}
