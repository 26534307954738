<section class="bg-grey-light position-relative" *ngIf=" sideNavService.sidenavRightCrossSellStep == 1">
  <div class="w-100 d-flex">
    <button (click)="onClose()" class="w-100 border-0 py-3 px-2 text-left">
      <span class="k-chevron-left mr-2"></span>{{'backToSite' | translate}}
    </button>
  </div>
  <div [class.px-3]="!mobileQuery.matches" [class.pt-3]="!mobileQuery.matches" [class.px-1]="mobileQuery.matches">
    <div class="bg-white row mx-0 p-1" *ngIf="sideNavService.productAdded">
      <img [src]="sideNavService.productAdded.productPictures[0].pictureUrlSmall"
           [alt]="sideNavService.productAdded.designationDynamic" [title]="sideNavService.productAdded.designationDynamic"
           class="w-52px h-auto" />
      <div class="col my-auto">
        <p class="mb-0 f-size-13px">
          {{sideNavService.productAdded.designationDynamic}}
          <br /><span class="font-weight-bold f-size-13px">
            {{'hasBeenHadTo' | translate}} <a href=""
                                              class="text-black text-decoration-underline f-size-13px">{{'yourCart' | translate}}</a>
          </span>
        </p>
      </div>
    </div>
    <div class="py-3">
      <h2 class="font-weight-extrabold f-size-20px text-italic">
        {{'dontForgetEquipmentAndMaintenance' | translate}}
      </h2>
    </div>
    <p class="f-size-13px">
      {{'addEquipementOrMaintenanceProduct' | translate}}, <span class="font-weight-bold f-size-13px">{{'moreYouAddMoreYouSave' | translate}} !</span>
    </p>
    <div class="row mx-0">
      <div class="col-12 px-2 mx-0" [class.my-2]="mobileQuery.matches"
           *ngIf="row && row.totalDiscountFromCrossSell > 0">
        <span class="text-black f-size-16px p-0 font-weight-bold">Économies en hausse ! Total des réductions : </span>
        <span class="text-main-gradient f-size-18px p-0 font-weight-bold"
              [innerHTML]="row.totalDiscountFromCrossSell | formatPrice"></span>
      </div>

      <div class="pb-3" *ngFor="let group of crossSellGroups" [class.col-6]="!mobileQuery.matches"
           [class.col-12]="mobileQuery.matches" [class.px-2]="!mobileQuery.matches" [class.px-0]="mobileQuery.matches">
        <p class="font-weight-bold f-size-16px mt-2 line-height-1">
          {{group.designation}}
        </p>
        <ng-container *ngFor="let crossSell of group.crossSells">
          <div class="bg-grey-light mb-1 rounded">
            <!-- ITEM IN CROSSELL -->
            <div class="crossell-full p-1 bg-white w-100 row mx-0"
                 *ngFor="let row of getRowsCrossSell(crossSell.crossSellId)">
              <div class="my-auto w-72px">
                <span class="rounded-pill bg-main-gradient text-black position-absolute d-flex item-badge-number">
                  <span class="m-auto font-weight-bold text-black f-size-12px text-black line-height-1">
                    {{row.quantityRequested}}
                  </span>
                </span>
                <img [src]="row.pictureUrlSmall" [alt]="row.designation" [title]="row.designation" class="w-100" />
              </div>
              <div class="col my-auto">
                <div>
                  <p class="f-size-12px mb-0 line-height-1">{{row.designation}}</p>
                </div>
                <div class="">
                  <p class=" mb-0 line-height-1">
                    <span class="bg-main-gradient px-1 pb-1 rounded w-auto" *ngIf="row.crossSellDiscount > 0">
                      <span class="f-size-10px">{{'groupBuy' | translate}} : </span>
                      <span class="f-size-10px font-weight-bold">
                        <span class="f-size-10px"
                              [innerHTML]="row.crossSellDiscount * row.quantityRequested | formatPrice"></span>
                        {{'ofDiscount' | translate}}
                      </span>
                    </span>
                  </p>
                </div>
                <div>
                  <span class="f-size-13px font-weight-bold mr-1"
                        [innerHTML]="row.priceValueTtc * row.quantityRequested | formatPrice"></span>
                  <span class="f-size-11px text-grey-dark text-decoration-linethrough"
                        *ngIf="row.priceValueTtcOff > row.priceValueTtc"
                        [innerHTML]="row.priceValueTtcOff * row.quantityRequested | formatPrice"></span>
                </div>
              </div>
              <div class="col-1 p-0">
                <button class="bg-grey-light rounded-circle p-1 d-flex border-0" (click)="onRemoveFromCart(row)">
                  <span class="m-auto k-trash f-size-18px"></span>
                </button>
              </div>
            </div>
            <!-- ADD NEW ITEM IN CROSSELL -->
            <!-- <button class="border-0 bg-white mb-0 w-100 py-1" *ngIf="getRowsCrossSell(crossSell.crossSellId).length > 0"
              (click)="onAddCrossSell(crossSell.crossSellId, crossSell.designation, crossSell.categories)">
              <p class="mb-0 d-flex">
                <span class="k-circle-plus f-size-15px my-auto"></span>
                <span class="f-size-11px ml-1 font-weight-bold my-auto"
                  *ngIf="crossSell.designation.includes('Ajouter un') && !crossSell.designation.includes('Ajouter une')">{{crossSell.designation.replace('Ajouter un', 'Ajouter un autre')}}</span>
                <span class="f-size-11px ml-1 font-weight-bold my-auto"
                  *ngIf="crossSell.designation.includes('Ajouter une')">{{crossSell.designation.replace('Ajouter une',
                  'Ajouter une autre')}}</span>
                <span class="f-size-11px ml-1 font-weight-bold my-auto"
                  *ngIf="!crossSell.designation.includes('Ajouter un') && !crossSell.designation.includes('Ajouter une')">
                  {{crossSell.designation}}</span>
              </p>
            </button> -->
          </div>
          <button class="crossell-empty rounded mb-2" *ngIf="getRowsCrossSell(crossSell.crossSellId).length == 0"
                  (click)="onAddCrossSell(crossSell.crossSellId, crossSell.designation, crossSell.categories)">
            <span class="h-100 w-80px d-flex position-relative">
              <img [src]="crossSell.fullUrlPictureSmall" [alt]="crossSell.designation" [title]="crossSell.designation"
                   class="smx-picto f-size-32px m-auto" />
              <span class="k-circle-plus position-absolute f-size-16px p-1 rounded-circle bg-main-gradient r-10px t-20"></span>
            </span>
            <span class="f-size-13px font-weight-bold my-auto">{{crossSell.designation}}</span>
          </button>
        </ng-container>
      </div>
    </div>
    <div class="position-sticky px-0 row mx-0 w-100 l-0px b-10px">
      <div class="w-100">
        <a [routerLink]="['/',  translateService.currentLang ? translateService.currentLang : translateService.defaultLang, 'document']"
           class="bg-main-gradient d-flex border-0 rounded w-100 text-decoration-none minh-56px">
          <p class="m-auto font-weight-bold" *ngIf="cart">
            {{'myCart' | translate}} | <span [innerHTML]="cart.totalWoShipTtc | formatPrice"></span>
            <span class="k-chevron-right"></span>
          </p>
        </a>
      </div>
    </div>
  </div>
</section>
<!-- LIST PRODUCT -->
<section class="bg-white" id="cross-sell" *ngIf="!selectedProduct && sideNavService.sidenavRightCrossSellStep == 2">
  <div *ngIf="filterMobileOpen" class="overlay cursor-pointer" (click)="this.onCloseFilters();"> </div>
  

  <button (click)="sideNavService.sidenavRightCrossSellStep = 1" class="w-100 border-0 py-3 px-2 text-left mh-60"><span
      class="k-chevron-left mr-2"></span>{{'back' | translate}}</button>

      <div class="bg-grey-light row mx-3 p-1 mt-3" *ngIf="sideNavService.productAdded">
        <img [src]="sideNavService.productAdded.productPictures[0].pictureUrlSmall"
          [alt]="sideNavService.productAdded.designationDynamic" [title]="sideNavService.productAdded.designationDynamic"
          class="w-52px h-auto" />
        <div class="col my-auto">
          <p class="mb-0 f-size-13px">
            {{sideNavService.productAdded.designationDynamic}}
            <br /><span class="font-weight-bold f-size-13px">
              Dans votre panier
            </span>
          </p>
        </div>
      </div>
  <div class="pt-4 mx-0 drawer-cross_sell" [class.px-3]="!mobileQuery.matches" [class.px-1]="mobileQuery.matches">
    <div class="pb-1">
      <h2 class="font-weight-extrabold f-size-20px text-italic">
        {{header}}
      </h2>
    </div>
    <button class="border-black text-black bg-white rounded-lg d-flex py-2 w-100 my-2"
            (click)="filterMobileOpen=!filterMobileOpen"
            [disabled]="(catalogFacade.getFetchProductsCrossSellState() | async)?.loading">
      <p class="m-auto">
        <span class="f-size-18px k-settings-2 text-main-gradient mr-2"></span>
        <span class="font-weight-bold">{{'refine' | translate}}</span>
        <span class="font-style-italic ml-1">
          ({{nbFiltersActive}} {{'aFilter' | translate}} {{ 'active' | translate }})
        </span>
      </p>
    </button>

    <!--<app-global-loader class="mx-auto" *ngIf="(catalogFacade.getFetchProductsCrossSellState() | async)?.loading">
    </app-global-loader>-->
    <div *ngIf="(catalogFacade.getFetchProductsCrossSellState() | async)?.loading" class="grid_cross-sell">
      <div class="bg-white item-drawer-product">
        <app-item-product-loader class="cursor-pointer"></app-item-product-loader>
      </div>
      <div class="bg-white item-drawer-product">
        <app-item-product-loader class="cursor-pointer"></app-item-product-loader>
      </div>
      <div class="bg-white item-drawer-product">
        <app-item-product-loader class="cursor-pointer"></app-item-product-loader>
      </div>
      <div class="bg-white item-drawer-product">
        <app-item-product-loader class="cursor-pointer"></app-item-product-loader>
      </div>
      <div class="bg-white item-drawer-product">
        <app-item-product-loader class="cursor-pointer"></app-item-product-loader>
      </div>
      <div class="bg-white item-drawer-product">
        <app-item-product-loader class="cursor-pointer"></app-item-product-loader>
      </div>
    </div>
    <div class="grid_cross-sell">

      <ng-container *ngFor="let product of products; let i = index">
        <div *ngIf="product.typeId != 13" class="bg-white item-drawer-product">
          <app-item-product [product]="product" [typeClient]="true" [i]="i" [count]="products.length"
                            [showInputQuantity]="false" class="cursor-pointer" [redirect]="false"
                            (productIdClicked)="onSelectProduct($event)">
          </app-item-product>
        </div>
      </ng-container>
    </div>

    <div *ngIf="totalPages" class="text-center px-1 mx-0 px-md-5 mx-md-5">
      <div class="d-flex justify-content-center">
        <button mat-button class="mb-3 border-black bg-white rounded px-3 py-2 d-flex mx-3"
                (click)="onSelectPage(pageNumber-1)" *ngIf="pageNumber > 1 ">
          <span class="k-arrow-left my-auto mr-2 f-size-20px"></span> <span class="my-auto font-weight-semibold">
            {{'previousPage' | translate}}
          </span>
        </button>
        <button mat-button class="mb-3 border-black bg-white rounded px-3 py-2 d-flex mx-3"
                (click)="onSelectPage(pageNumber+1)" *ngIf="pageNumber < totalPages">
          <span class="my-auto font-weight-semibold">{{'nextPage' | translate}}</span> <span class="k-arrow-right my-auto ml-2 f-size-20px"></span>
        </button>
      </div>
      <hr>
      <div class="totalpage text-center mx-auto justify-content-center d-flex">
        <a mat-button *ngFor="let page of [].constructor(totalPages); let i = index"
           [class.cursor-pointer]="pageNumber != i+1" [class.selectpage]="pageNumber == i+1"
           class="mx-1 text-decoration-none rounded-circle d-flex" (click)="onSelectPage(i+1)">
          <span class="m-auto line-height-1">{{i+1}}</span>
        </a>
      </div>
      <hr>
    </div>
  </div>
</section>
<!-- <section class="w-100 h-100vh bg-grey content-loader" *ngIf="(catalogFacade.getFetchProductForCrossSellState() | async)?.loading">
  <div class="w-100 pb-100 pb-md-55 bg-grey content-loader">
  </div>
  <div class="w-100 pt-5 h-100 px-3 bg-grey-light content-loader">
    <div class="col-12 row px-0 mx-0 option">
      <div class="d-flex option-img-three text-decoration-none p-0 position-relative pb-30 bg-grey"></div>
      <div class="d-flex option-img-three text-decoration-none p-0 position-relative pb-30 bg-grey"></div>
      <div class="d-flex option-img-three text-decoration-none p-0 position-relative pb-30 bg-grey"></div>
      <div class="d-flex option-img-three text-decoration-none p-0 position-relative pb-30 bg-grey"></div>
      <div class="d-flex option-img-three text-decoration-none p-0 position-relative pb-30 bg-grey"></div>
      <div class="d-flex option-img-three text-decoration-none p-0 position-relative pb-30 bg-grey"></div>
    </div>
  </div>
</section> -->
<!-- ITEM OPEN  -->
<section class="bg-white minh-100 row mx-0 px-0 flex-column align-item-start position-relative"
         *ngIf="sideNavService.sidenavRightCrossSellStep == 3 && selectedProduct">

  <button (click)="sideNavService.sidenavRightCrossSellStep = 2; selectedProduct = null;"
          class="position-absolute z-index-3 d-flex border-0 py-3 px-2 bg-transparent text-left mb-0 mh-60">
    <span class="k-chevron-left mr-2 my-auto"></span>
    <div class=" my-auto mr-2">{{'back' | translate}}</div>
  </button>

  <!-- <div *ngIf="!mobileQuery.matches && selectedPicture" class="col-md-2 d-none d-md-block pl-0"
    [class.height-slider]="product.productPictures.length > 5">
    <img [src]="productPicture.pictureUrlSmall"
          *ngFor="let productPicture of product.productPictures" [alt]="productPicture.designation"
          class="w-100 h-auto mb-3 cursor-pointer" loading="lazy" [title]="productPicture.designation"
          [class.select-shadow]="productPicture.pictureId == selectedPicture.pictureId">
    </div> -->
  <!--  -->
  
  <div class="w-100 row mx-0 px-0">

    <div class="px-0  col-12 mt-0 h-fit-content" [class.bg-grey]="!selectedColor"
         [style]="'background:' + (selectedColor ? selectedColor : '') ">
      <div class="text-center " *ngIf="carouselNbImages == 1">
        <div class="h-auto mx-auto" [class.w-50]="!mobileQuery.matches" [class.w-100]="mobileQuery.matches">
          <div class="item">
            <img [src]="selectedProduct.productPictures[0].pictureUrlLarge"
                 [alt]="selectedProduct.productPictures[0].designation"
                 [title]="selectedProduct.productPictures[0].designation" class="img-carousel w-100">
          </div>
        </div>
      </div>

      <div class="text-center " *ngIf="carouselNbImages > 1 && mobileQuery.matches">
        <ngu-carousel #myCarouselImages [inputs]="carouselConfigImages" [dataSource]="carouselItemsImages"
                      class="h-auto">
          <div *nguCarouselDef="let item;" class="item">
            <img [src]="item.pictureUrlLarge" [alt]="item.designation" [title]="item.designation"
                 class="img-carousel w-100">
          </div>
          <button *ngIf="carouselNbImages > 2 && !myCarouselImages.isLast" NguCarouselNext
                  class="rightRs btn-carousel position-absolute bg-black border-0 px-0">
            <span class="k-chevron-right f-size-22px text-white m-auto"></span>
          </button>

          <button *ngIf="carouselNbImages > 2 && !myCarouselImages.isFirst" NguCarouselPrev
                  class="leftRs btn-carousel position-absolute bg-black border-0 px-0">
            <span class="k-chevron-left f-size-22px text-white m-auto"></span>
          </button>

          <div class="myPoint row px-0 mx-0 position-absolute w-100" NguCarouselPoint>
            <div *ngFor="let j of myCarouselImages.pointNumbers; let i = index"
                 [class.active]="i==myCarouselImages.activePoint" (click)="myCarouselImages.moveTo(i)" class="carousel"
                 [ngStyle]="{'width': styleCarouselImages}"></div>
          </div>
        </ngu-carousel>

      </div>

      <div class="text-center " *ngIf="carouselNbImages > 1 && !mobileQuery.matches">
        <ngu-carousel #myCarouselImages2 [inputs]="carouselConfigImages2" [dataSource]="carouselItemsImages"
                      class="h-auto">
          <div *nguCarouselDef="let item;" class="item">
            <img [src]="item.pictureUrlLarge" [alt]="item.designation" [title]="item.designation"
                 class="img-carousel w-100">
          </div>
          <button *ngIf="carouselNbImages > 2 && !myCarouselImages2.isLast" NguCarouselNext
                  class="rightRs btn-carousel position-absolute bg-black border-0 px-0">
            <span class="k-chevron-right f-size-22px text-white m-auto"></span>
          </button>

          <button *ngIf="carouselNbImages > 2 && !myCarouselImages2.isFirst" NguCarouselPrev
                  class="leftRs btn-carousel position-absolute bg-black border-0 px-0">
            <span class="k-chevron-left f-size-22px text-white m-auto"></span>
          </button>

          <div class="myPoint row px-0 mx-0 position-absolute w-100" NguCarouselPoint>
            <div *ngFor="let j of myCarouselImages2.pointNumbers; let i = index"
                 [class.active]="i==myCarouselImages2.activePoint" (click)="myCarouselImages2.moveTo(i)" class="carousel"
                 [ngStyle]="{'width': styleCarouselImages2}"></div>
          </div>
        </ngu-carousel>

      </div>
    </div>
    <!-- BLOC DESIGNATION -->
    <div class="w-100 mt-2 mt-md-3 text-center">
      <!-- DESIGNATION -->
      <h1 class="col-12 px-0 mb-0 f-size-md-20px f-size-16px font-weight-medium font-style-normal text-black">
        {{selectedProduct.designationDynamic}}
        <!--Désignation -->
      </h1>
      <div class="d-flex justify-content-between px-3" [class.pt-2]="mobileQuery.matches">
        <!-- ID -->
        <p class="px-0 m-0 f-size-12px font-weight-normal text-greylight">Référence : {{selectedProduct.productId}}</p>

        <!-- RATING -->
        <div class="justify-content-start px-0 rating">
          <mat-icon class="my-auto mx-0 f-size-18px rep-star" *ngFor="let i of [1,2,3,4,5]"
                    [class.text-main-gradient]="ratingService.starFillType(i, selectedProduct.averageRating) == 0 || ratingService.starFillType(i, selectedProduct.averageRating) == 1"
                    [class.text-grey]="ratingService.starFillType(i, selectedProduct.averageRating) == 2"></mat-icon>
        </div>
      </div>
    </div>

    <div class="px-3 py-2 col-12 mt-0">
      <!-- VARIANT -->
      <div class="col-12 justify-content-center px-0">
        <div class="row mx-0 px-0">
          <ng-container *ngFor="let productVariant of productVariants">
            <ng-container *ngIf="productVariant.variants.length > 1">
              <div class="col-12 row mx-0 px-0 justify-content-start mb-1"
                   *ngIf="productVariant.variants && productVariant.variants.length > 0">
                <p class="f-size-14px  mb-0 px-0" [class.col-12]="mobileQuery.matches">
                  {{ productVariant.designation | translate}}
                </p>
              </div>

              <div class="col-12 row px-0 mx-0 option">
                <ng-container *ngFor="let variantDetails of productVariant.variants">
                  <button (click)="onSelectProduct(variantDetails.productId)"
                          class="row option-img-three text-decoration-none p-0 position-relative"
                          [class.select]="variantDetails.current == true" [class.disabled]="variantDetails.current == true"
                          *ngIf="variantDetails.pictureFile">
                    <!--<img [src]="variantDetails.pictureFile" [alt]="variantDetails.designation" *ngIf="getProductBgColor(variantDetails) != '' && productVariant.attributId == 29 && mobileQuery.matches"
  [title]="variantDetails.designation" [class.col-12]="mobileQuery.matches"  [class.px-0]="mobileQuery.matches" [style]="'background:' + (getProductBgColor(variantDetails) && productVariant.attributId == 29 ? getProductBgColor(variantDetails) : '') "/>-->
                    <div class="color-box" [class.w-100]="mobileQuery.matches" *ngIf="getProductBgColor(variantDetails) != '' && productVariant.attributId == 29"
                         [style]="'background:' + (getProductBgColor(variantDetails,true) && productVariant.attributId == 29 ? getProductBgColor(variantDetails,true) : '') ">
                    </div>
                    <span class="f-size-13px m-auto font-weight-medium line-1 designation" [class.w-100]="mobileQuery.matches">{{variantDetails.designation}}</span>
                    <span *ngIf="variantDetails.disponibility == 2"
                          class="rounded-circle bg-danger text-white position-absolute d-flex border-white tag-available">
                      <span class="font-weight-normal k-bell text-white m-auto f-size-12px"></span>
                    </span>
                    <span *ngIf="variantDetails.disponibility == 1"
                          class="rounded-circle bg-main-gradient position-absolute d-flex border-white tag-available">
                      <span class="font-weight-normal k-warning m-auto f-size-12px"></span>
                    </span>
                  </button>

                  <button (click)="onSelectProduct(variantDetails.productId)"
                          class="d-flex option-text-six text-decoration-none position-relative"
                          [class.select]="variantDetails.current == true" [class.disabled]="variantDetails.current == true"
                          *ngIf="!variantDetails.pictureFile">
                    <span class="f-size-16px m-auto font-weight-medium designation">{{variantDetails.designation}}</span>
                    <span *ngIf="variantDetails.disponibility == 2"
                          class="rounded-circle bg-danger text-white position-absolute d-flex border-white tag-available">
                      <span class="font-weight-normal k-bell text-white m-auto f-size-12px"></span>
                    </span>
                    <span *ngIf="variantDetails.disponibility == 1"
                          class="rounded-circle bg-main-gradient position-absolute d-flex border-white tag-available">
                      <span class="font-weight-normal k-warning m-auto f-size-12px"></span>
                    </span>
                  </button>
                </ng-container>
              </div>
            </ng-container>
          </ng-container>
        </div>


      </div>


    </div>
    <div class="col-12 row mt-2 mx-0 justify-content-center">
      <!-- POURCENTAGE REDUCTION TTC-->
      <div class="col-12 justify-content-center d-flex mb-1" *ngIf="selectedProduct.percentOff > 0">
        <span class="f-size-12px font-weight-bold rounded text-center px-1 my-auto border-1-5px border-grey">
          -{{selectedProduct.percentOff}}%
        </span>
      </div>

      <!-- PRICE TTC-->
      <div class="col-12 justify-content-center d-flex">
        <div class="px-0 text-left">
          <span *ngIf="!selectedProduct.containerHasAllSamePrice && selectedProduct.typeId == 9"
                class="f-size-14px text-danger font-weight-bold mr-2">{{'from' | translate}}</span>
          <span class="product_price mt-auto f-size-26px font-weight-bold px-0 "
                [class.text-danger]="selectedProduct.percentOff > 0"
                [innerHTML]="selectedProduct.priceValueTtc | formatPrice"></span>
        </div>
      </div>

      <!-- REDUCTION TTC-->
      <div *ngIf="selectedProduct.percentOff > 0" class="col-12 justify-content-center d-flex">
        <span class="f-size-lg-20px f-size-16px font-weight-semibold mt-auto px-0 my-auto mr-2 text-decoration-linethrough"
              [innerHTML]="selectedProduct.priceValueTtcOff | formatPrice"></span>
        <span class="f-size-14px">Vous économisez</span>
        <span class="ml-1 f-size-14px"
              [innerHTML]="selectedProduct.priceValueTtcOff - selectedProduct.priceValueTtc | formatPrice"></span>
      </div>
    </div>
  </div>

  <div class="col-12 mt-auto mb-2">
    <div class="border-black rounded" *ngIf="selectedProduct.crossSellDiscount > 0">
      <p class="f-size-14px mb-0 p-2 text-center">
        <span class="font-weight-bold f-size-14px">Achat groupé :</span>
        <br>
        <span class="font-weight-bold f-size-18px text-main-gradient" [innerHTML]="selectedProduct.crossSellDiscount  | formatPrice"></span>
        <span class="f-size-14px">de réduction supplémentaire</span>
        <br />
        <span class="f-size-14px">Soit</span>
        <span class="product_price mt-auto f-size-26px font-weight-bold px-0 text-danger ml-2" [innerHTML]="(selectedProduct.priceValueTtc- selectedProduct.crossSellDiscount ) | formatPrice"></span>
      </p>
    </div>

    <div class="col-12 text-center mt-2 px-0" *ngIf="selectedProduct.disponibility == 0">
      <!-- BUTTON ADD TO CARD -->
      <button color="primary" (click)="onAddToCart()" class="btn-theme mx-0 col-12 mx-auto minh-60px">
        <span class="f-size-14px font-weight-bold m-auto d-flex">
          <span *ngIf="attributsToBeSelected.length == 0"> {{'addToCart' | translate }}</span>
          <span *ngIf="attributsToBeSelected.length > 0">{{attributsToBeSelected[0]}}</span>
          <!-- <span *ngIf="attributsToBeSelected.length == 0"></span> -->
          <span *ngIf="attributsToBeSelected.length == 0" class="ml-3 k-basket f-size-18px my-auto"></span>
        </span>
      </button>
    </div>

    <!-- PREORDER -->
    <div class="col-12 row mx-0 px-0 text-center btn-fixed-mobile mt-1 justify-content-between"
         *ngIf="selectedProduct.disponibility == 1">
      <div class=" col-12 px-0 mt-2">
        <button mat-button disabled class="btn-theme font-weight-semibold  minh-60px">
          <span class="f-size-14px font-weight-semibold m-auto d-flex justify-content-center">
            <span class="ml-3 f-size-18px my-auto k-warning"></span>&nbsp;{{'inStockThe' | translate}} {{
            selectedProduct.dateNextArrival | date:'dd MMM yyyy'
            }}
          </span>
        </button>
      </div>
      <div class="col-12 mt-2 px-0 ">
        <button mat-button color="primary" (click)="onAddToCart()"
                *ngIf="selectedProduct.typeId != 9 && selectedProduct.typeId != 12" class="btn-theme mx-0 mx-auto minh-60px">
          <span class="f-size-14px font-weight-semibold m-auto d-flex">
            {{'preorder' | translate }} <span class="ml-3 k-basket f-size-18px my-auto"></span>
          </span>
        </button>
      </div>
    </div>

    <!-- MAIl FOR STOCK  *ngIf="!selectedProduct.canBeAddedToCart &&  selectedProduct.typeId != 9"-->
    <div class="col-12 row mx-0 px-0 text-center mt-1 justify-content-between"
         *ngIf="selectedProduct.disponibility == 2">
      <div class=" col-12 px-0">
        <button mat-button disabled class="btn-theme font-weight-semibold minh-60px">
          <span class="f-size-14px font-weight-semibold m-auto d-flex justify-content-center">
            <span class="ml-1 f-size-18px my-auto k-no-delivery"></span>&nbsp;{{'outOfStock' | translate}}
          </span>
        </button>
      </div>
      <div class=" col-12 mt-2 px-0">
        <button mat-button (click)="onOpenNotifyStock()" class="btn-alert font-weight-semibold minh-60px">
          <span class="f-size-14px font-weight-semibold m-auto d-flex justify-content-center">
            {{'notifyMeInReturnStock' | translate}} <span class="ml-1 f-size-18px text-white my-auto k-bell"> </span>
          </span>
        </button>
      </div>
    </div>
  </div>
</section>



<!-- FILTER MAIN POPUP -->
<div *ngIf="filterMobileOpen" class="mw-md-50 position-absolute bg-white row mx-0 px-0 filter-mobile filter-1">
  <div class="w-100">
    <mat-toolbar class="mat-toolbar bg-black w-100">
      <mat-toolbar-row class=" navbar px-0 d-flex w-100">
        <span class="k-chevron-left opacity-0 f-size-28px ml-3 mr-2 my-auto"></span>
        <span class="mr-auto ml-0 my-auto f-size-18px font-style-italic text-white font-weight-semibold ">
          {{'refine' | translate }}
        </span>
        <div class="bg-main-gradient h-100 d-flex w-56px">
          <span (click)="onCloseFilters();" class="k-x text-black f-size-28px ml-3 mr-2 my-auto"></span>
        </div>
      </mat-toolbar-row>
    </mat-toolbar>
    <div>
      <button class="bg-white border-0 w-100 px-3 mt-3 d-flex w-100" (click)="selectedOrder = true">
        <span class="my-auto f-size-18px font-style-italic font-weight-sbold text-black">
          {{
          'filterBy' | translate
          }}
        </span>
        <span class="my-auto f-size-14px font-style-italic text-black ml-2">
          <span *ngIf="selectedOrderChoice.key == 0">{{'defaultSorting' | translate }}</span>
          <span *ngIf="selectedOrderChoice.key == 1">{{'increasingPrice' | translate }}</span>
          <span *ngIf="selectedOrderChoice.key == 2">{{'decreasingPrice' | translate }}</span>
          <span *ngIf="selectedOrderChoice.key == 3">{{'nameAToZ' | translate }}</span>
          <span *ngIf="selectedOrderChoice.key == 4">{{'nameZToA' | translate }}</span>
          <span *ngIf="selectedOrderChoice.key == 5">{{'popularity' | translate }}</span>
        </span>
        <span class="ml-auto my-auto k-chevron-right f-size-28px text-main-gradient"></span>
      </button>
      <hr class="my-3 w-100" />
      <div class="bg-white border-0 w-100 px-3 d-flex w-100">
        <span class="my-auto f-size-18px font-style-italic font-weight-sbold text-black">
          {{
          'filter' | translate
          }}
        </span>
        <button class="bg-white border-0 my-auto f-size-12px font-style-italic text-black ml-2 text-decoration-underline"
                (click)="onRemoveAllFilters()">
          {{'eraseAll' | translate }}
        </button>
      </div>

      <!-- NO DISPLAY WHEN NO FILTER -->
      <!-- *ngIf="nbFiltersActive != 0" -->
      <div class="row m-0 px-3" *ngIf="nbFiltersActive != 0">

        <span *ngFor="let filter of filters" class="mt-3">
          <ng-container *ngFor="let value of filter.values">
            <button *ngIf="value.selected" (click)="onRemoveFilter(filter, value.valueId?.toString()!)"
                    class="border-0 bg-black text-white rounded-sm px-2 py-2 mr-3 mb-1">
              <span class="k-x text-main-gradient mr-2"></span>{{value.valueDesignation}}
            </button>
          </ng-container>
        </span>
      </div>
      <hr class="my-3 w-100" />

      <div *ngFor="let filter of filters">
        <button class="bg-white border-0 w-100 px-3 d-flex" (click)="onSelectFilterMobile(filter)">
          <span class="my-auto f-size-18px font-style-italic font-weight-sbold text-black">
            {{filter.filterDesignation}}
          </span>
          <span class="ml-auto my-auto k-chevron-right f-size-28px text-main-gradient"></span>
        </button>
        <hr class="my-3 w-100" />
      </div>


    </div>
  </div>
  <div class="mt-auto p-3 w-100 d-flex justify-content-between mx-0 position-sticky b-0px">
    <button (click)="onRemoveAllFilters()"
            class="border-black bg-white rounded col-6 f-size-14px font-weight-medium mr-1 h-60px">
      {{'eraseAll' | translate }}
    </button>
    <button class="bg-main-gradient border-0 rounded col-6 f-size-14px font-weight-semibold ml-1 h-60px"
            (click)="onFilterList()">
      {{'seeProducts' | translate }}
    </button>
  </div>
</div>

<div *ngIf="selectedFilterMobile && !selectedOrder"
     class="mw-md-50 position-absolute bg-white row mx-0 px-0 filter-mobile filter-2">
  <div class="w-100">
    <mat-toolbar class="mat-toolbar bg-black w-100">
      <mat-toolbar-row class=" navbar bg-black px-0 d-flex w-100">
        <span (click)="selectedFilterMobile = null"
              class="k-chevron-left text-main-gradient f-size-28px ml-3 mr-2 my-auto"></span>
        <span (click)="selectedFilterMobile = null"
              class="mr-auto ml-0 my-auto f-size-18px font-style-italic text-white font-weight-semibold ">
          {{'back' | translate }}
        </span>
        <div (click)="onCloseFilters(); onFilterListMobile();" class="bg-main-gradient h-100 d-flex w-56px">
          <span class="k-x text-black f-size-28px ml-3 mr-2 my-auto"></span>
        </div>
      </mat-toolbar-row>
    </mat-toolbar>
    <div>
      <button class="bg-white border-0 w-100 px-3 mt-3 d-flex w-100">
        <span class="my-auto f-size-18px font-style-italic font-weight-sbold text-black">{{selectedFilterMobile.filterDesignation}}</span>
        <!--<span class="my-auto f-size-18px font-style-italic font-weight-sbold text-black">{{'nicotineValue' | translate }}</span>-->
      </button>
      <!--<div class="row mx-0 px-3 mt-3" *ngIf="selectedFilterMobile.type != 2">
        <div class="input-group has-validation border-grey-light rounded">
          <div class="input-group-prepend">
            <span class="input-group-text k-search border-0 bg-white"></span>
          </div>
          <input type="text" class="form-control border-0" [(ngModel)]="searchFilter"
            placeholder="{{'search' | translate }}">

        </div>
      </div>-->

      <div class="bg-white border-0 px-3 row mx-0 w-100 mt-3 pt-2 height-filter">

        <div class="row mx-0 px-0 w-100" *ngIf="selectedFilterMobile.type != 2">
          <mat-selection-list (click)="onSelectFilterValueMobile()" [formControl]="selectedFilterMobile.ctrlFilter"
                              class="w-100">
            <span *ngFor="let filterBy of selectedFilterMobile.values">
              <span *ngIf="!searchFilter || filterBy.valueDesignation?.toLowerCase()?.includes(searchFilter.toLowerCase())">
                <mat-list-option [value]="filterBy.valueId" checkboxPosition="before">
                  {{filterBy.valueDesignation}}
                </mat-list-option>
                <hr class="my-2 w-100" />
              </span>
            </span>
          </mat-selection-list>
        </div>

        <div class="d-flex flex-column mx-0 px-0 w-100" *ngIf="selectedFilterMobile.type == 2">

          <mat-form-field appearance="outline" class="w-100">
            <!-- <mat-label>Min</mat-label> -->
            <mat-icon class="text-grey-dark rep-euro" matPrefix></mat-icon>
            <input matInput placeholder="Min" [formControl]="ctrlFilterPriceMin" min="0">
          </mat-form-field>

          <!-- <input class="rounded border-grey w-100" style="height:45px;" min="0" placeholder="Min"
          [formControl]="ctrlFilterPriceMin"> -->
          <!-- <input class="rounded border-grey w-100" style="height:45px;" max="10000" placeholder="Max"
          [formControl]="ctrlFilterPriceMax"> -->

          <mat-form-field appearance="outline" class="w-100 mt-2">
            <!-- <mat-label>Min</mat-label> -->
            <mat-icon class="text-grey-dark rep-euro" matPrefix></mat-icon>
            <input matInput placeholder="Max" [formControl]="ctrlFilterPriceMax" max="10000">
          </mat-form-field>

        </div>

      </div>

    </div>
  </div>
  <div class="  mt-auto p-3 w-100 d-flex justify-content-between mx-0 position-sticky b-0px">
    <button (click)="onRemoveAllFilters()"
            class="border-black bg-white rounded col-6 f-size-14px font-weight-medium mr-1 h-60px">
      {{'eraseAll' | translate }}
    </button>
    <button class="bg-main-gradient border-0 rounded col-6 f-size-14px font-weight-semibold ml-1 h-60px"
            (click)="onFilterList()">
      {{'seeProducts' | translate }}
    </button>
  </div>
</div>


<div *ngIf="selectedOrder" class="mw-md-50 position-absolute bg-white row mx-0 px-0 filter-mobile  filter-3">
  <div class="w-100">
    <mat-toolbar class="mat-toolbar bg-black w-100">
      <mat-toolbar-row class=" navbar px-0 d-flex w-100">
        <span (click)="selectedOrder = false"
              class="k-chevron-left text-main-gradient f-size-28px ml-3 mr-2 my-auto"></span>
        <span (click)="selectedOrder = false"
              class="mr-auto ml-0 my-auto f-size-18px font-style-italic text-white font-weight-semibold ">
          {{'back' | translate }}
        </span>
        <div (click)="selectedOrder = false; onCloseFilters();" class="bg-main-gradient h-100 d-flex w-56px">
          <span class="k-x text-black f-size-28px ml-3 mr-2 my-auto"></span>
        </div>
      </mat-toolbar-row>
    </mat-toolbar>
    <div>

      <div class="bg-white border-0 px-3 row mx-0 w-100 mt-3 pt-2 height-filter">
        <div class="col-12 row px-4 py-3">
          <input type="radio" id="default" name="filter" value="0" [checked]="selectedOrderChoice.key == 0"
                 (change)="onChangeOrder($event)">
          <label for="default" class="col-12 my-auto row">
            <div class="radiobox rounded-pill d-flex m-auto">
              <span class="k-check text-white m-auto"></span>
            </div>
            <span class="col">{{'defaultSorting' | translate }}</span>
          </label>
        </div>
        <div class="col-12 row px-4 py-3">
          <input type="radio" id="price-asc" name="filter" value="1" [checked]="selectedOrderChoice.key == 1"
                 (change)="onChangeOrder($event)">
          <label for="price-asc" class="col-12 my-auto row">
            <div class="radiobox rounded-pill d-flex m-auto">
              <span class="k-check text-white m-auto"></span>
            </div>
            <span class="col">{{'increasingPrice' | translate }}</span>
          </label>
        </div>
        <div class="col-12 row px-4 py-3">
          <input type="radio" id="price-desc" name="filter" value="2" [checked]="selectedOrderChoice.key == 2"
                 (change)="onChangeOrder($event)">
          <label for="price-desc" class="col-12 my-auto row">
            <div class="radiobox rounded-pill d-flex m-auto">
              <span class="k-check text-white m-auto"></span>
            </div>
            <span class="col">{{'decreasingPrice' | translate }}</span>
          </label>
        </div>
        <div class="col-12 row px-4 py-3">
          <input type="radio" id="az" name="filter" value="3" [checked]="selectedOrderChoice.key == 3"
                 (change)="onChangeOrder($event)">
          <label for="az" class="col-12 my-auto row">
            <div class="radiobox rounded-pill d-flex m-auto">
              <span class="k-check text-white m-auto"></span>
            </div>
            <span class="col">{{'nameAToZ' | translate }}</span>
          </label>
        </div>
        <div class="col-12 row px-4 py-3">
          <input type="radio" id="za" name="filter" value="4" [checked]="selectedOrderChoice.key == 4"
                 (change)="onChangeOrder($event)">
          <label for="za" class="col-12 my-auto row">
            <div class="radiobox rounded-pill d-flex m-auto">
              <span class="k-check text-white m-auto"></span>
            </div>
            <span class="col">{{'nameZToA' | translate }}</span>
          </label>
        </div>

        <div class="col-12 row px-4 py-3">
          <input type="radio" id="pop" name="filter" value="5" [checked]="selectedOrderChoice.key == 5"
                 (change)="onChangeOrder($event)">
          <label for="pop" class="col-12 my-auto row">
            <div class="radiobox rounded-pill d-flex m-auto">
              <span class="k-check text-white m-auto"></span>
            </div>
            <span class="col">{{'popularity' | translate }}</span>
          </label>
        </div>

      </div>

    </div>
  </div>
  <div class="  mt-auto p-3 w-100 d-flex justify-content-between mx-0 position-sticky b-0px">
    <div class="  col-6 f-size-14px font-weight-medium mr-1 h-60px"></div>
    <button class="bg-main-gradient border-0 rounded col-6 f-size-14px font-weight-semibold ml-1 h-60px"
            (click)="selectedOrder = false; onCloseFilters(); onFilterList()">
      {{'seeProducts' | translate }}
    </button>
  </div>
</div>
